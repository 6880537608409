import { useMediaQuery, useTheme } from '@mui/material'

interface UseScaleArrowsReturn {
  scaleArrows: number
}
export const useScaleArrows = (): UseScaleArrowsReturn => {
  const theme = useTheme()

  const scaleArrows = useMediaQuery(theme.breakpoints.up('lg')) ? 1.0 : 0.5

  return { scaleArrows }
}
