import { UserType } from '../../models/graphql'
import { useMe } from './useMe'
import { useUserType } from './useUserType'

type Return = {
  isAdmin: boolean
  isOwnTreatment: boolean
  isAssistantOfTreatment: boolean
}

export const usePermissions = (treatmentDoctorId?: string): Return => {
  const { data: { me: user } = {} } = useMe({ fetchPolicy: 'cache-only' })

  const profileId = user?.profile?.id
  const userType = useUserType()
  const assistantDoctorIds = user?.assistantDoctorIds
  const isDoctor = userType === UserType.Doctor
  const isAssistant = userType === UserType.Assistant

  const isOwnTreatment = isDoctor && treatmentDoctorId === profileId

  const isAssistantOfTreatment = !!(
    isAssistant &&
    treatmentDoctorId &&
    assistantDoctorIds?.includes(treatmentDoctorId)
  )

  return {
    isAdmin: user?.isAdmin ?? false,
    isOwnTreatment,
    isAssistantOfTreatment,
  }
}
