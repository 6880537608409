import { Box, Typography } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import React from 'react'

interface Props {
  filterValue: string
}

export const NoFilterResultContentComponent: React.FC<Props> = ({
  filterValue,
}) => {
  const { t } = useTranslation()

  return (
    <Box display="flex" justifyContent="center" mt={2}>
      <Typography variant="body1">
        {`${t('appointment:noResultFoundFor')}: "${filterValue}"`}
      </Typography>
    </Box>
  )
}
