import { useEffect, useState } from 'react'
import {
  ArrowProps,
  calculateArrowLevels,
  getArrowConfigByDistance,
  getBrokenArrowConfig,
  getMaximumArrowLevel,
  getWidthForLevel,
} from '../Arrow'
import { useScaleArrows } from './useScaleArrows'
import { Appointment, Treatment } from '../utils/treatmentPanel.types'
import { appointmentDistanceFormatter } from '../../../../../../utils/appointmentDistanceFromatter'
import { useTranslation } from 'react-i18next'

interface UseArrowProps {
  filterValue: string
  visibleAppointments: Appointment[]
  hoveredAppointmentId: string | null
  treatment: Treatment
}

interface UseArrowPropsReturn {
  arrowProps: ArrowProps[]
  widthOfArrows: number
}
export const useArrowProps = ({
  filterValue,
  visibleAppointments,
  hoveredAppointmentId,
  treatment,
}: UseArrowProps): UseArrowPropsReturn => {
  const [t] = useTranslation()
  const [arrowProps, setArrowProps] = useState<ArrowProps[]>([])

  const { scaleArrows } = useScaleArrows()
  const widthOfArrows = getWidthForLevel(
    getMaximumArrowLevel(arrowProps),
    scaleArrows
  )

  const createArrowProps = (): ArrowProps[] => {
    const newArrowProps = (
      filterValue.length === 0 ? visibleAppointments : []
    ).reduce<ArrowProps[]>((acc, appointment) => {
      const arrows = appointment.dependent
        .filter((dependent) =>
          visibleAppointments.find(
            (appointment) => dependent.toId === appointment.id
          )
        )
        .map((dependent) => ({
          start: `arrow-row-${appointment.id}`,
          end: `arrow-row-${dependent.toId}`,
          labelText: appointmentDistanceFormatter(
            dependent.distance,
            t,
            dependent.type
          ),
          level: 1,
          highlighted:
            hoveredAppointmentId === dependent.toId ||
            hoveredAppointmentId === appointment.id,
          ...(dependent.isBroken
            ? getBrokenArrowConfig()
            : getArrowConfigByDistance(dependent.distance)),
          maxDistance: undefined,
        }))
      return [...acc, ...arrows]
    }, [])
    calculateArrowLevels(
      newArrowProps,
      visibleAppointments.map((appointment) => `arrow-row-${appointment.id}`)
    )

    return newArrowProps
  }

  useEffect(() => {
    setArrowProps(createArrowProps())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredAppointmentId, filterValue.length])

  useEffect(() => {
    setArrowProps([])
    setTimeout(() => setArrowProps(createArrowProps()), 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatment?.appointments])

  return {
    arrowProps,
    widthOfArrows,
  }
}
