export const formatAddress = (
  postalCode?: string | null,
  city?: string | null,
  street?: string | null
): string | undefined => {
  if (!postalCode && !city && !street) {
    return undefined
  }

  const formattedPostalCode = postalCode ? `${postalCode},` : ''
  const formattedCity = city ?? ''
  const formattedStreet = street ?? ''

  return `${formattedPostalCode} ${formattedCity} ${formattedStreet}`.trim()
}
