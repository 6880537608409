import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  sendMessageToPatients,
  sendMessageToPatientsVariables,
} from '../../../../models/graphql'

import { SEND_MESSAGE_TO_PATIENTS } from '../operations/doctorPatientOperations'

type Options = MutationHookOptions<
  sendMessageToPatients,
  sendMessageToPatientsVariables
>

type Return = MutationTuple<
  sendMessageToPatients,
  sendMessageToPatientsVariables
>

export const useSendMessageToPatients = (options: Options = {}): Return => {
  return useMutation<sendMessageToPatients, sendMessageToPatientsVariables>(
    SEND_MESSAGE_TO_PATIENTS,
    options
  )
}
