import React from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { deleteDoctorsOwnPatients as BulkDismissResult } from '../../../../models/graphql'
import { useTranslation } from 'react-i18next'
import { Close as CloseIcon } from '@mui/icons-material'

import SuccessIcon from '../../../../assets/icons/success_icon.png'
import { PatientListItemType } from '../types/patientList.types'
import { formatTAJ } from '../../../../utils'
import { BETMEN_LIST_ITEM_CARD_BOX_SHADOW } from '../../../../theme'

export interface ConfirmDialogProps {
  selectedPatients: PatientListItemType[]
  deletedPatients: BulkDismissResult
  onClose?: () => void
}
const DeletePatientsResultModal: React.FC<ConfirmDialogProps> = ({
  selectedPatients,
  deletedPatients,
  onClose,
}) => {
  const theme = useTheme()
  const { t } = useTranslation('patients')

  const notDeletedPatients = deletedPatients?.deleteDoctorsOwnPatients
    ?.filter((patient) => !patient.success)
    .map((notDeletedPatient) => notDeletedPatient.profile.patient)

  const isAllDeleted = notDeletedPatients?.length === 0
  const isPartlyDeleted = notDeletedPatients?.length !== selectedPatients.length
  const isNoneDeleted = notDeletedPatients?.length === selectedPatients.length

  return (
    <Dialog fullWidth open={!!deletedPatients} onClose={onClose}>
      <DialogTitle>{t('deleteModal.title')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          {(isAllDeleted || isPartlyDeleted) && (
            <>
              <img src={SuccessIcon} alt="successIcon" />

              <Typography marginTop={3}>
                {isAllDeleted && t('deleteModal.allDeleted')}
                {isPartlyDeleted &&
                  t('deleteModal.partlyDeleted', {
                    success:
                      selectedPatients.length -
                      (notDeletedPatients?.length ?? 0),
                    total: selectedPatients.length,
                  })}
              </Typography>
            </>
          )}
          {isPartlyDeleted && (
            <Divider sx={{ width: '100%', marginTop: 3, marginBottom: 3 }} />
          )}
          {(isPartlyDeleted || isNoneDeleted) && (
            <Typography color="error">{t('deleteModal.notDeleted')}</Typography>
          )}
          <Box
            marginTop={3}
            display="flex"
            flexDirection="column"
            width="100%"
            maxHeight="350px"
            overflow="scroll"
          >
            {notDeletedPatients?.map((patient) => (
              <Grid
                container
                key={patient?.id}
                mb={2}
                display="flex"
                alignItems="center"
                p={2}
                overflow="hidden"
                borderRadius={theme.spacing(2)}
                boxShadow={BETMEN_LIST_ITEM_CARD_BOX_SHADOW}
                minHeight="53px"
              >
                <Grid xs={5} item>
                  <Typography variant="body2">
                    {t('common:formattedNameFull', {
                      firstName: patient?.firstName,
                      lastName: patient?.lastName,
                      title: patient?.title,
                    })}
                  </Typography>
                </Grid>
                <Grid xs={3} item>
                  {formatTAJ(patient?.tajNumber || '')}
                </Grid>
                <Grid xs={4} item>
                  {t('common:dateFormatted', {
                    date: patient?.birthDate,
                  })}
                </Grid>
              </Grid>
            ))}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export { DeletePatientsResultModal }
