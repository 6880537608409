import React, { useEffect, useState } from 'react'
import { BetmenPage, Tab } from '../../../common/components/BetmenPage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { PatientList } from './components/PatientList'
import { AddButton } from '../../../common/components/AddButton/AddButton'
import { AddPatientModal } from './components/AddPatientModal'
import { usePatientsList } from '../../../common/hooks/usePatientsList'
import { PAGE_SIZE } from '../../../config'
import { PatientListFilters } from './components/PatientListFilters'
import { PatientFilter, PatientStatus, UserType } from '../../../models/graphql'
import { useDebounce } from '../../../common/hooks'
import { useMe } from '../../../common/hooks/useMe'

enum PatientListTabs {
  Active = 0,
  Removed = 1,
}

export const DoctorPatients: React.FC = () => {
  const { t } = useTranslation()
  const { data: { me } = {} } = useMe()
  const isAssistant = me?.userType === UserType.Assistant

  const doctorIds = isAssistant
    ? me?.assistantDoctorIds ?? []
    : [me?.profile?.id ?? '']

  const [selectedTabIndex, setSelectedTabIndex] = useState<PatientListTabs>(
    PatientListTabs.Active
  )

  const tabs: Tab[] = [
    { label: t('doctor:patientList.current') },
    { label: t('doctor:patientList.removed') },
  ]

  const [filterInput, setFilterInput] = useState<PatientFilter>({})
  const filterData: PatientFilter = useDebounce(filterInput)

  const [selectedDoctorIds, setSelectedDoctorIds] =
    useState<string[]>(doctorIds)

  const filters = {
    doctorIds: selectedDoctorIds.length > 0 ? selectedDoctorIds : doctorIds,
    filterData: {
      ...filterData,
      status:
        selectedTabIndex === PatientListTabs.Active
          ? PatientStatus.ACTIVE
          : PatientStatus.DISCHARGED,
    },
  }

  const { refetch, fetchMore, ...patientList } = usePatientsList({
    variables: {
      ...filters,
      paginationInput: {
        offset: 0,
        limit: PAGE_SIZE,
      },
    },
  })

  const fetchMorePatients = (offset: number) =>
    fetchMore({
      variables: {
        ...filters,
        paginationInput: {
          offset,
          limit: PAGE_SIZE,
        },
      },
    })

  useEffect(() => {
    refetch()
  }, [filterData, refetch, selectedTabIndex, selectedDoctorIds])

  const [isAddPatientsModalOpen, toggleIsAddPatientModalOpen] =
    useState<boolean>(false)

  const [isFilterPanelOpen, setFilterPanelOpen] = useState(true)

  const handleFilterOpen = () => {
    setFilterPanelOpen(true)
  }

  const handleFilterClose = () => {
    setFilterPanelOpen(false)
  }

  return (
    <>
      <BetmenPage
        title={t('menu:doctor.patients')}
        tabs={tabs}
        onTabChange={setSelectedTabIndex}
        withoutPadding
        headerRightContent={
          <AddButton
            handleAdd={() => toggleIsAddPatientModalOpen(true)}
            buttonText={t(`patients:new`)}
          />
        }
        filters={
          <PatientListFilters
            isFilterPanelOpen={isFilterPanelOpen}
            handleFilterClose={handleFilterClose}
            filterInput={filterInput}
            setFilterInput={setFilterInput}
            setSelectedDoctorIds={setSelectedDoctorIds}
          />
        }
      >
        <PatientList
          filterInput={filterInput}
          setFilterInput={setFilterInput}
          isFilterPanelOpen={isFilterPanelOpen}
          handleFilterOpen={handleFilterOpen}
          {...patientList}
          fetchMore={fetchMorePatients}
          refetch={refetch}
        />
      </BetmenPage>

      {isAddPatientsModalOpen && (
        <AddPatientModal
          patientList={patientList.data}
          isOpen={isAddPatientsModalOpen}
          toggleIsOpen={toggleIsAddPatientModalOpen}
        />
      )}
    </>
  )
}
