import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  deleteDoctorsOwnPatients,
  deleteDoctorsOwnPatientsVariables,
} from '../../../../models/graphql'

import { DELETE_DOCTORS_OWN_PATIENTS } from '../operations/doctorPatientOperations'

type Options = MutationHookOptions<
  deleteDoctorsOwnPatients,
  deleteDoctorsOwnPatientsVariables
>

type Return = MutationTuple<
  deleteDoctorsOwnPatients,
  deleteDoctorsOwnPatientsVariables
>

export const useDeleteDoctorOwnPatients = (options: Options = {}): Return => {
  return useMutation<
    deleteDoctorsOwnPatients,
    deleteDoctorsOwnPatientsVariables
  >(DELETE_DOCTORS_OWN_PATIENTS, options)
}
