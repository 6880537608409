import { useQuery } from '@apollo/client'
import { GET_CITY_LIST } from '../../operations/cityOperations'
import { getCities } from '../../models/graphql'

type Return = {
  data: string[]
  loading: boolean
}

export const useCities = (): Return => {
  const { data, loading } = useQuery<getCities, unknown>(GET_CITY_LIST, {
    fetchPolicy: 'cache-and-network',
  })

  return { data: data?.getLocations ?? [], loading }
}
