import { getTreatment_getTreatment_appointments as Appointment } from '../../../../../../models/graphql'

export const getParentsIds = (
  appointments: Appointment[],
  appointmentId: string,
  target: string[] = [],
  recursive = true
): string[] => {
  appointments.forEach((appointment) =>
    appointment.dependent.forEach((dep) => {
      if (dep.toId === appointmentId && dep.fromId) {
        target.push(dep.fromId)
        if (recursive) {
          getParentsIds(appointments, dep.fromId, target)
        }
      }
    })
  )

  return target
}
