import { gql } from '@apollo/client'

export const GET_CITIES_BY_POSTAL_CODE = gql`
  query getLocationByPostalCode($postalCode: String!) {
    getLocationByPostalCode(postalCode: $postalCode)
  }
`

export const GET_CITY_LIST = gql`
  query getCities {
    getLocations
  }
`
