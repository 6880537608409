import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { useDebounce } from '../../hooks/helper/useDebounce'
import { Controller, useFormContext } from 'react-hook-form'
import isFunction from 'lodash/isFunction'
import {
  listAppointmentInfos_listAppointmentInfos as Appointment,
  getAssistantDoctors_getAssistantProfile_ownDoctors as AssistantDoctor,
} from '../../../models/graphql'
import { get, uniqueId } from 'lodash'

type MultiSelectorProps<T extends string | Appointment | AssistantDoctor> =
  TextFieldProps &
    Pick<
      AutocompleteProps<T, true, true, false>,
      'isOptionEqualToValue' | 'getOptionLabel'
    > & {
      name: string
      isOptionsLoading: boolean
      options: T[]
      refetchOptions?: () => void
    }

export const MultiSelector = <
  T extends string | Appointment | AssistantDoctor
>({
  name,
  isOptionsLoading,
  options,
  refetchOptions,
  disabled,
  isOptionEqualToValue,
  getOptionLabel,
  ...props
}: MultiSelectorProps<T>): JSX.Element => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const searchTerm = useDebounce(inputValue)

  const { control } = useFormContext()

  useEffect(() => {
    isFunction(refetchOptions) ? refetchOptions() : null
  }, [searchTerm, refetchOptions])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete<T, true, true>
          {...field}
          disabled={disabled}
          multiple
          disableClearable
          loading={isOptionsLoading}
          options={options}
          onChange={(_, data) => {
            field.onChange(data)
          }}
          onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
          isOptionEqualToValue={
            isFunction(isOptionEqualToValue)
              ? isOptionEqualToValue
              : (option, value) => option === value
          }
          getOptionLabel={
            isFunction(getOptionLabel) ? getOptionLabel : (item) => `${item}`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              {...props}
              placeholder={t('common:search')}
              variant="outlined"
              name={name}
            />
          )}
          renderOption={
            isFunction(getOptionLabel)
              ? (renderProps, option) => {
                  return (
                    // use ID from option for key to prevent console errors if tha label string is a duplicate
                    <li {...renderProps} key={get(option, 'id', uniqueId())}>
                      {getOptionLabel(option)}
                    </li>
                  )
                }
              : undefined
          }
        />
      )}
    />
  )
}
