import {
  getTreatment_getTreatment_appointments as Appointment,
  getTreatment_getTreatment_appointments_doctor as AppointmentDoctor,
  getTreatment_getTreatment as Treatment,
} from '../../../../../../models/graphql'

export type { Appointment, AppointmentDoctor, Treatment }

export enum TreatmentPanelTab {
  Events = 0,
  TreatmentLog = 1,
}

export interface AddAppointmentModalParams {
  isOpen: boolean
  appointmentType?: string
  doctor?: AppointmentDoctor
  appointment?: Appointment
  proposedDate?: Date
}

export type RouteParams = {
  patientId: string
  treatmentId: string
  appointmentId?: string
  action?: string
}
