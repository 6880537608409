import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useTheme,
  FilterOptionsState,
} from '@mui/material'
import { Autocomplete } from '@mui/material'
import { useMutation } from '@apollo/client'

import { CalendarMonth } from '../CalendarSelector/CalendarMonth'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useStoreActions } from '../../../../../store/store.hooks'
import {
  getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas as TreatmentSchema,
  addProtocolVariables,
  addProtocol as AddProtocolType,
  BnoCodeChore,
} from '../../../../../models/graphql'
import { ADD_PROTOCOL } from '../../../../../operations/treatmentOperations'
import dayjs from 'dayjs'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useTreatmentSchemas } from '../../../TreatmentSchema/hooks/useTreatmentSchemas'

type Props = {
  isOpen: boolean
  treatmentId: string | undefined
  onClose?: () => void
  onDone?: () => void
  treatmentBnoCodes?: Omit<BnoCodeChore, '__typename'>[] | null
}

const AddProtocolModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onDone,
  treatmentId,
  treatmentBnoCodes,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const solidBorder = `1px solid ${theme.palette.common.black}34`
  const today = dayjs().startOf('day').toISOString()
  const [startDate, setStartDate] = useState<string>(today)
  const [selectedTreatment, setSelectedTreatment] =
    useState<TreatmentSchema | null>()
  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const selectedLanguage = useSelectedLanguage()

  const { treatmentSchemas } = useTreatmentSchemas({
    variables: {
      treatmentSchemaFilter: {
        bnoCodes: treatmentBnoCodes?.map((bnoCode) => bnoCode.code),
      },
    },
  })

  const handleClose = () => {
    setStartDate(today)
    setSelectedTreatment(null)
    onClose && onClose()
  }

  const [addProtocol, { loading }] = useMutation<
    AddProtocolType,
    addProtocolVariables
  >(ADD_PROTOCOL, {
    onCompleted: () => {
      setToast({
        text: t('appointment:creationMessage.success'),
        type: 'success',
      })
      onDone && onDone()
      handleClose()
    },
  })

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    value: TreatmentSchema | null
  ) => {
    setSelectedTreatment(value)
  }

  const handleFilterOptions = (
    options: TreatmentSchema[],
    state: FilterOptionsState<TreatmentSchema>
  ): TreatmentSchema[] => {
    const searchTerm = state.inputValue.toLowerCase()
    const bnoCodes = treatmentBnoCodes?.map((item) => item.code.toLowerCase())

    return options
      .filter((option) => {
        if (!treatmentBnoCodes?.length || !option.bnoCodes?.length) {
          return true
        }
        return option.bnoCodes
          .map((item) => item.code.toLowerCase())
          .some((item) => bnoCodes?.some((code) => item.includes(code)))
      })
      .filter((option) => {
        const bnoMatches = option.bnoCodes
          ?.map(({ code }) => code.toLowerCase())
          .some((item) => item.includes(searchTerm))

        const optionLabelMatches = state
          .getOptionLabel(option)
          .toLowerCase()
          .includes(searchTerm)

        return optionLabelMatches || bnoMatches
      })
  }

  const onSubmit = () => {
    if (selectedTreatment && treatmentId) {
      const treatmentData = {
        treatmentId,
        treatmentSchemaId: selectedTreatment?.id,
        startDate,
      }
      addProtocol({
        variables: {
          treatmentData,
        },
      })
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
    >
      <DialogTitle>
        {t('treatment:doctor.assignProtocolDialogTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('treatment:doctor.addProtocolSchemaDialogText')}
        </DialogContentText>
        <Box mb={2} mt={2}>
          <Autocomplete<TreatmentSchema, false, false>
            options={treatmentSchemas.filter((schema) => !schema.disabled)}
            getOptionLabel={(option) => option.title[selectedLanguage]}
            filterOptions={handleFilterOptions}
            onChange={handleChange}
            getOptionDisabled={(option) => !!option.disabled}
            renderOption={(props, option) => {
              return (
                // We need this to pass unique keys to the options
                <li {...props} key={option.id}>
                  {option.title[selectedLanguage]}
                </li>
              )
            }}
            data-cy="AddProtocolModal-TextField-selectProtocolType"
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                label={t('treatment:doctor.chooseProtocolPlan')}
                variant="outlined"
              />
            )}
          />
        </Box>
        <Box border={solidBorder} borderRadius={1}>
          <CalendarMonth
            selectedDate={startDate}
            handleDateChange={(date) => setStartDate(date.toISOString())}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('common:cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={!startDate || !selectedTreatment || loading}
          data-cy="AddProtocolModal-Button-addProtocol"
        >
          {t('common:save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { AddProtocolModal }
