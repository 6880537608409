import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import dayjs from 'dayjs'
import { get } from 'lodash'
import React, { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { SurveyCategoryMultiSelector } from '../../../../../common/components/selectors/SurveyCategoryMultlSelector'
import { SurveySchemaSelector } from '../../../../../common/components/selectors/SurveySchemaSelector'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  getSurveySchemas_getSurveySchemas_surveySchemas as SurveySchema,
  getTreatment_getTreatment_surveys as Survey,
  SurveyCategoryEnum,
} from '../../../../../models/graphql'
import { formatDayjsToStringDateType } from '../../../../../utils/formatToDateType'
import {
  DEFAULT_FILLOUT_TIME_IN_DAYS,
  SurveyDatePicker,
} from '../../../SurveyStore/components/Surveys/SurveyDatePicker'

export interface AddSurveyModalParams {
  isOpen: boolean
  survey?: Survey
}
export interface AddSurveyFormValues {
  surveySchema: SurveySchema | null
  categories: SurveyCategoryEnum[]
  fillableFromDate: string
  fillableUntilDate: string
}

interface Props {
  params: AddSurveyModalParams
  onClose: () => void
  onSave: (formValues: AddSurveyFormValues) => void
  title: string
  submitButtonText: string
}

export const AddSurveyModal: React.FC<Props> = ({
  params: { isOpen, survey },
  onClose,
  onSave,
  title,
  submitButtonText,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const isEdit = useMemo(() => !!survey, [survey])

  const today = dayjs().startOf('day')
  const oneWeekLater = today.add(DEFAULT_FILLOUT_TIME_IN_DAYS, 'day')

  const formMethods = useForm<AddSurveyFormValues>({
    defaultValues: {
      surveySchema: !!survey
        ? {
            ...survey.surveySchema,
            user: { __typename: 'User', id: '' },
          }
        : null,
      categories: survey?.surveySchema?.categories ?? [],
      fillableFromDate: !!survey
        ? formatDayjsToStringDateType(dayjs(survey.fillableFrom))
        : formatDayjsToStringDateType(today),
      fillableUntilDate: !!survey
        ? formatDayjsToStringDateType(dayjs(survey.fillableUntil))
        : formatDayjsToStringDateType(oneWeekLater),
    },
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = formMethods

  const handleClose = () => {
    reset()
    onClose()
  }

  const onSubmit = handleSubmit(async (formValues) => {
    onSave(formValues)
  })

  return (
    <FormProvider {...formMethods}>
      <Dialog
        open={isOpen}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box
            mb={2}
            mt={2}
            sx={{
              background: '#ECEFF6',
              padding: 2,
              borderRadius: 2,
              minWidth: '500px',
            }}
          >
            <Controller
              control={control}
              name="categories"
              render={({ field }) => (
                <SurveyCategoryMultiSelector
                  field={field}
                  label={t('survey:surveys.category')}
                  hasError={!!errors.categories}
                  helperText={get(errors, 'categories.message', '')}
                  disabled={isEdit}
                  sx={{ background: colorPalette.background.default }}
                />
              )}
            />
            <Controller
              control={control}
              name="surveySchema"
              rules={{
                validate: (value) =>
                  !value?.id
                    ? (t('messages:warnings.required') as string)
                    : true,
              }}
              render={({ field }) => (
                <SurveySchemaSelector
                  field={field}
                  helperText={t('messages:warnings.required')}
                  label={t('survey:surveys.title')}
                  filter={{ categories: watch('categories') }}
                  hintText={t('protocol:schedule.addSurveySchema.hint')}
                  error={!!errors.surveySchema}
                  disabled={isEdit}
                  sx={{ background: colorPalette.background.default }}
                />
              )}
            />
          </Box>
          <SurveyDatePicker
            fillableFromName="fillableFromDate"
            fillableUntilName="fillableUntilDate"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t('common:cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            data-cy="AddSurveyModal-Button-addSurvey"
          >
            {submitButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}
