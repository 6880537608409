import { BoxProps } from '@material-ui/core'
import { Box, Grid, Grow, useTheme } from '@mui/material'
import { isFunction } from 'lodash'
import React, { FC } from 'react'
import { PAGE_SIZE } from '../../../config'
import { BETMEN_LIST_ITEM_CARD_BOX_SHADOW } from '../../../theme'
import { BetmenListActions } from './BetmenListActions'
import { BetmenListItemCardCell } from './BetmenListItemCardCell'

interface BetmenListItemCardProps extends BoxProps {
  click?: () => void
  animate?: boolean
  'data-cy'?: string
}

export const BetmenListItemCard: FC<BetmenListItemCardProps> = ({
  click,
  animate = true,
  'data-cy': dataCy,
  children,
  sx,
  ...props
}): JSX.Element => {
  const theme = useTheme()
  const isClickable = isFunction(click)
  const indexOfComponent = (props as typeof props & { itemindex: number })
    .itemindex

  return (
    <Grow
      in={animate}
      timeout={indexOfComponent < PAGE_SIZE - 3 ? indexOfComponent * 200 : 500}
    >
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        p={2}
        overflow="hidden"
        borderRadius={theme.spacing(2)}
        boxShadow={BETMEN_LIST_ITEM_CARD_BOX_SHADOW}
        sx={{
          background: theme.palette.common.white,
          cursor: isClickable ? 'pointer' : 'auto',
          transition: isClickable ? 'all .2s ease-in-out' : 'none',
          ':hover': {
            transform: isClickable ? 'scale(1.005)' : 'none',
          },
          ...sx,
        }}
        onClick={click}
        {...props}
        data-cy={dataCy || 'BetmenListItemCard'}
      >
        <Grid container alignItems="center">
          {React.Children.map(children, (child, i) => {
            if (
              React.isValidElement(child) &&
              (child.type === BetmenListItemCardCell ||
                child.type === BetmenListActions ||
                child.type === Box)
            ) {
              return React.cloneElement(child, { ...child.props, cellindex: i })
            }

            console.warn(
              'Invalid child component found in BetmenListItemCard. Allowed: BetmenListItemCardCell, BetmenListActions or Box',
              child
            )
            return child
          })}
        </Grid>
      </Box>
    </Grow>
  )
}
