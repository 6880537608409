import React from 'react'
import { PatientListItemType } from '../types/patientList.types'
import {
  Avatar,
  BetmenListItemCard,
  BetmenListItemCardCell,
  Status,
  StatusEnum,
} from '../../../../common/components'
import { useHistory } from 'react-router-dom'
import { useTranslation, useUserType } from '../../../../common/hooks'
import { Box, Checkbox, Typography } from '@mui/material'
import { formatTAJ } from '../../../../utils'
import { CHECKBOX_WIDTH } from './patients.constants'

interface PatientListItemProps {
  patient: PatientListItemType
  selectedPatients: PatientListItemType[]
  setSelectedPatients: (ids: PatientListItemType[]) => void
}
const PatientListItem: React.FC<PatientListItemProps> = ({
  patient,
  selectedPatients,
  setSelectedPatients,
}) => {
  const { t } = useTranslation()

  const history = useHistory()
  const userType = useUserType()

  const handlePatientClick = () => {
    if (patient.isActive) {
      history.push(`/${userType}/patients/${patient.id}`)
    }
  }

  const handlePatientCheckBoxClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()
    if (selectedPatients.includes(patient)) {
      setSelectedPatients(
        selectedPatients.filter(
          (selectedPatient) => selectedPatient.id !== patient.id
        )
      )
    } else {
      setSelectedPatients([...selectedPatients, patient])
    }
  }

  const showCheckBox = patient.isActive

  return (
    <BetmenListItemCard
      data-cy={`Patient-Box-${patient.tajNumber}`}
      click={() => handlePatientClick()}
    >
      <Box width={showCheckBox ? CHECKBOX_WIDTH : 0}>
        {showCheckBox && (
          <Checkbox
            checked={selectedPatients?.includes(patient)}
            onClick={(e) => handlePatientCheckBoxClick(e)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
          />
        )}
      </Box>
      <BetmenListItemCardCell display="flex" alignItems="center">
        <Avatar
          firstName={patient.firstName}
          lastName={patient.lastName}
          size="tiny"
        />
        <Typography variant="body2">
          {t('common:formattedNameFull', {
            firstName: patient.firstName,
            lastName: patient.lastName,
            title: patient.title,
          })}
        </Typography>
      </BetmenListItemCardCell>
      <BetmenListItemCardCell>
        {formatTAJ(patient.tajNumber)}
      </BetmenListItemCardCell>
      <BetmenListItemCardCell>
        {t('common:dateFormatted', {
          date: patient.birthDate,
        })}
      </BetmenListItemCardCell>
      <BetmenListItemCardCell>
        <Status
          status={patient.isActive ? StatusEnum.Active : StatusEnum.Deleted}
          title={patient.isActive ? undefined : t('patients:removed')}
        />
      </BetmenListItemCardCell>
    </BetmenListItemCard>
  )
}

export { PatientListItem }
