import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Treatment } from '../TreatmentPanel/utils/treatmentPanel.types'

interface Props {
  treatment: Treatment
}

export const AppointmentDeletedState: React.FC<Props> = ({ treatment }) => {
  const { t } = useTranslation()
  return (
    <Box p={2}>
      <Typography variant="h6" color="error">
        {t('treatment:doctor.removedTreatment')}
      </Typography>
      <Typography variant="body2">
        {t('treatment:doctor.reasonOfClosing')}:
      </Typography>
      <Typography variant="subtitle1">{treatment?.comment}</Typography>
    </Box>
  )
}
